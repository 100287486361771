import { type Events_newsletter, type Events_upcomingEvents } from '~/v1/_types/Events';
import { EventCard } from '~/v1/components/cards/event/event';
import { mapEventCard } from '~/v1/components/cards/event/event.utils';
import { Column } from '~/v1/components/grid/column/column';
import { Newsletter } from '~/v1/components/newsletter/newsletter';
import { NewsLetterId } from '~/v1/components/newsletter/newsletter.interface';
import { RelatedGridModule } from '~/v1/modules/grid/related/related';
import { NewsletterModule } from '~/v1/modules/newsletter/newsletter';

import styles from './upcomingEvents.module.scss';

export interface UpcomingEventsSectionProps {
  newsletter: Events_newsletter;
  upcomingEvents: Events_upcomingEvents[];
}

export function UpcomingEventsSection({ newsletter, upcomingEvents }: UpcomingEventsSectionProps) {
  if (!upcomingEvents.length) {
    return (
      <NewsletterModule id={NewsLetterId.UPCOMING_EVENTS} newsletter={newsletter}>
        <Column sm={5} md={4} lg={4} offsetLeft={{ lg: 1 }} className={styles.noEventsWrapper}>
          <p className="bodySmallTypography">There are currently no upcoming events</p>
        </Column>
      </NewsletterModule>
    );
  }

  return upcomingEvents.length === 1
    ? renderSingleUpcomingEvent(newsletter, upcomingEvents[0])
    : renderMultipleUpcomingEvents(newsletter, upcomingEvents);
}

function renderSingleUpcomingEvent(
  newsletter: Events_newsletter,
  upcomingEvent: Events_upcomingEvents,
) {
  return (
    <NewsletterModule id={NewsLetterId.UPCOMING_EVENTS} newsletter={newsletter}>
      <Column sm={6} md={6} lg={8} offsetLeft={{ lg: 1 }}>
        <h2 className="sectionTitleTypography module">Upcoming events</h2>
      </Column>
      <Column sm={4} md={3} lg={3} offsetLeft={{ lg: 1 }} offsetRight={{ md: 1, lg: 1 }}>
        <EventCard className={styles.eventCard} {...mapEventCard(upcomingEvent)} isEventsPage />
      </Column>
    </NewsletterModule>
  );
}

function renderMultipleUpcomingEvents(
  newsletter: Events_newsletter,
  upcomingEvents: Events_upcomingEvents[],
) {
  const newsletterColumn = (
    <Newsletter
      id={NewsLetterId.UPCOMING_EVENTS}
      className="overflowRight"
      title={newsletter.description}
      disclaimer={newsletter.disclaimer}
    />
  );

  return (
    <>
      <RelatedGridModule
        label="Upcoming events"
        labelClassName="sectionTitleTypography module"
        content={[...upcomingEvents, newsletterColumn]}
      />
    </>
  );
}
