import { type Article } from '~/v1/_types/Article';
import { Button } from '~/v1/components/button/button';
import { ButtonMode, ButtonType } from '~/v1/components/button/button.interface';
import { useFilterContext } from '~/v1/components/filter/filter.context';
import { Column } from '~/v1/components/grid/column/column';
import { Grid } from '~/v1/components/grid/grid';
import { ListItem } from '~/v1/components/list/item/item';
import { mapListItem } from '~/v1/components/list/item/item.utils';
import { List } from '~/v1/components/list/list';

import styles from './filters.module.scss';

export const EventsFiltersSection: React.FC = () => {
  const { filterData, filterCount, onFilterLoadMore } = useFilterContext();

  return (
    <div className="section">
      <List>
        {(filterData as Article[]).map(data => (
          <ListItem key={data.id} {...mapListItem(data)} />
        ))}
      </List>

      {filterCount > filterData.length && (
        <Grid className={styles.loadMoreButton}>
          <Column offsetLeft={{ lg: 1 }} sm={5} md={7} lg={7}>
            <Button
              onClick={onFilterLoadMore}
              type={ButtonType.Primary}
              mode={ButtonMode.Light}
              text="Load more events"
            />
          </Column>
        </Grid>
      )}
    </div>
  );
};
